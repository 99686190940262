import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Badges from "../components/Repeating/Badges";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonUnderline";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Our Firm | Brierton, Jones & Jones | Estate Planning Firm"
        description="Brierton, Jones & Jones is one of the few firms that focuses exclusively on trusts and estates. With over 30 years of experience, we ensure your estate is handled with care and precision!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  WHO WE ARE
                </div>
                <h1>About Our Firm</h1>
                <p>
                  Brierton, Jones & Jones, LLP has provided San Diego County
                  families with practical legal insights, attentive service, and
                  compassion for over 30 years. We're one of the few firms in
                  the county that focuses exclusively on trusts and estates,
                  which means you benefit from our expertise and decades of
                  experience.
                </p>
                <ButtonSolid modal="modal-contact" text="Contact Us" />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/14.0 About Us/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-blue py-18 md:mb-0 md:py-24">
        <div className="container">
          <Badges />
        </div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-7 md:min-h-[774px] md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <div className="relative z-10 hidden md:block">
                <StaticImage
                  src="../images/14.0 About Us/Philosophy yellow.png"
                  loading="lazy"
                  width={560}
                />
              </div>
              <div className="left-0 top-0 hidden h-full md:absolute md:block md:w-[30vw]">
                <StaticImage
                  src="../images/14.0 About Us/Philosophy blue.jpg"
                  loading="lazy"
                  width={560}
                  className="h-full"
                  imgClassName="object-right"
                />
              </div>

              <div className="relative z-10 md:hidden">
                <StaticImage
                  src="../images/14.0 About Us/2.0 philosophy.png"
                  loading="lazy"
                  width={560}
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <h2>Our Philosophy</h2>
              <p className="mb-0">
                One of the greatest compliments on our work is client referrals,
                including that many of our clients return to us generation after
                generation. We support you by helping secure your future and are
                there to help you through difficulties that require a legal
                hand. Our services are always provided with the utmost integrity
                and discretion.
              </p>
            </div>
          </div>
        </div>
      </section>

      <WhyUs />
      <Attorneys className="!mb-0" />

      <section className="relative py-20 md:py-32">
        <div className="absolute left-0 top-0 hidden h-full w-full md:block">
          <StaticImage
            src="../images/14.0 About Us/3.0 carreers background desktop.jpg"
            loading="lazy"
            className="h-full w-full"
          />
        </div>

        <div className="absolute left-0 top-0 h-full w-full md:hidden">
          <StaticImage
            src="../images/14.0 About Us/3.0 carreers background mobile.jpg"
            loading="lazy"
            className="h-full w-full"
          />
        </div>
        <div className="container">
          <div className="relative rounded-3xl bg-white py-20 md:py-28">
            <header className="mx-auto max-w-[680px] text-center">
              <h2>Join the Team at Brierton, Jones & Jones, LLP</h2>
              <p>
                If you’re a San Diego law professional who cares as much as we
                do about helping clients plan for their future and meet the
                challenges life can sometimes bring, we want to hear from you.{" "}
              </p>
              <ButtonUnderline href="#" text="Explore Openings" />
            </header>
          </div>
        </div>
      </section>

      <Testimonials />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
